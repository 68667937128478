<template>
  <div>
    <div
      class="personal-item"
      @click="$router.push({ name: 'homepage-navigation', query: { userId: list.user.userId } })"
    >
      <user-avatar
        :obj="{
          avatar: list.user ? list.user.avatar : '',
          name: list.user ? list.user.userName : ''
        }"
      />
      <div class="box-left">
        <div class="title">{{ list.user ? list.user.userName : '' }}</div>
        <div class="content">{{ list.user ? list.user.mainJobName : '' }}</div>
        <div class="grade" v-if="typeof list.sumScore === 'number'">
          绩效综合得分<span class="bold">{{ list.sumScore }}分</span>
        </div>
      </div>
      <div class="box-right">
        <div class="status-box">
          <div class="level" :class="list.pfLevel">{{ list.pfLevel }}</div>
          <!-- 1 - 试用期；2 - 刚转正；3 - 转正；4 - 离职； -->
          <div class="status">{{ list.userJobState | formateVal }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userAvatar from '@/components/initiate/user-avatar'
export default {
  name: 'personal-item-card',
  components: {
    userAvatar
  },
  props: {
    list: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    avatar: {
      get() {
        return require('../../assets/images/profile.png')
      }
    }
  },
  filters: {
    formateVal(val) {
      // <!-- 1 - 试用期；2 - 刚转正；3 - 转正；4 - 离职； -->
      let obj = {
        1: '试用期',
        2: '刚转正',
        3: '',
        4: '离职'
      }
      return obj[val]
    }
  },
  methods: {
    errorHandler() {
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.personal-item {
  display: flex;
  flex: 1;
  padding-bottom: 20px;
  cursor: pointer;
  /deep/.avatar-box {
    align-items: flex-start;
  }
  .box-left {
    margin-left: 10px;
    cursor: pointer;
    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: $lightblack;
      line-height: 16px;
      margin-bottom: 7px;
    }
    .content {
      font-size: 13px;

      font-weight: 400;
      color: $grey;
      line-height: 13px;
      margin-bottom: 18px;
    }
    .bold {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: $lightblack;
    }
    .grade {
      font-size: 14px;
      font-weight: 400;
      color: $darkgrey;
      line-height: 14px;
    }
  }
  .box-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .status-box {
      .level {
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: $darkgrey;
        line-height: 18px;
      }
      .status {
        font-size: 14px;

        font-weight: 400;
        color: $red;
        line-height: 13px;
        margin-top: 5px;
      }
      .C {
        color: $red;
      }
      .S {
        color: $C89557;
      }
      .A {
        color: $blue;
      }
    }
  }
}
</style>
