<template>
  <div class="personal-result-view">
    <div class="result-main">
      <div class="main-top">
        <result-footer :obj="{ name: name, date: date }" :list="levelData" />
      </div>
      <tojoy-pagination-list
        :data="list"
        :total="total"
        :size="size"
        @page-change="handleChangePage"
        :loading="loading"
        :show-radius="false"
      >
        <template #item="{data}">
          <tojoy-card :data="data" schema="people">
            <template #downcard>
              <personal-item-card :list="data" />
            </template>
          </tojoy-card>
        </template>
      </tojoy-pagination-list>
    </div>
  </div>
</template>
<script>
import personalItemCard from '@/components/organizational/personal-item-card.vue'
import { getStaffEvalResult } from '@/api/api_organization'
import { resultMixin } from '@/mixins/organize/resultMixin.js'
export default {
  name: 'personal-result',
  mixins: [resultMixin],
  components: { personalItemCard },
  methods: {
    /**
     * 分页
     */
    handleChangePage(val) {
      this.page = val
      this.loading = true
      this.getList()
    },
    /**
     *列表数据
     */
    getList() {
      let params = {
        // partakeOrgId: this.orgId
        pocId: this.orgId
      }
      this.handleApi(params, getStaffEvalResult)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/personalResult.scss';
</style>
