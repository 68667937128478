import resultFooter from '@/components/organizational/result-footer'
import TojoyPaginationList from '@/components/business/pagination-list'
import TojoyCard from '@/components/business/card'
export const resultMixin = {
  props: {
    date: {
      type: [Number, String],
      default: null
    },
    orgId: {
      type: [Number, String],
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    levelList: {
      type: String,
      default: ''
    }
  },
  components: { resultFooter, TojoyPaginationList, TojoyCard },
  data() {
    return {
      loading: false,
      page: 1,
      size: 10,
      total: 0,
      list: [],
      levelData: [
        {
          grade: 0,
          level: 'S',
          text: '卓越'
        },
        {
          grade: 0,
          level: 'A',
          text: '优秀'
        },
        {
          grade: 0,
          level: 'B',
          text: '合格'
        },
        {
          grade: 0,
          level: 'C',
          text: '待改进'
        }
      ]
    }
  },
  methods: {
    handleApi(params, callback) {
      let param = {
        date: this.date,
        page: this.page,
        size: this.size
      }
      callback({ ...param, ...params })
        .then(res => {
          let { list, total } = res.data
          this.list = list
          this.total = total
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  mounted() {
    let list = JSON.parse(this.levelList)
    this.levelData.forEach(el => {
      el.grade = list[el.level]
    })
    this.loading = true
    this.getList()
  }
}
